import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Sections from "../components/contact/sections"
import { navigate } from "gatsby"

const ContactPage = () => {
  const [option, setOption] = useState("");
  const onSelectOption = (val) => {
    setOption(val);
    if(val==="available"){
      navigate("/available-dogs/")
      return false;
    }
    if(val==="coming-soon"){
      navigate("/coming-soon-dogs/")
      return false;
    }
  }
  return <Layout footer_layout="contact" colored_header={true}>
    <Seo title="Contact" />
    <Sections.Section1 onSelectOption={onSelectOption} />
    {option==="surrender" && <Sections.Section2/>}
    {option==="adopt" && <Sections.Section3/>}
    {option==="foster" && <Sections.Section4/>}
    {option==="volunteer" && <Sections.Section5/>}
    {option==="get-notify" && <Sections.Section6/>}
    {option==="ask-a-question" && <Sections.Section7/>}
    <Sections.SideBar />


  </Layout>
}

export default ContactPage